import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

// Connects to data-controller="patient-search"
export default class extends Controller {
  static values = {
    url: String,
  };

  connect() {
    const basePath = this.urlValue;

    new TomSelect(this.element, {
      valueField: "id",
      labelField: "name",
      searchField: "name",
      load: function (query, callback) {
        const url = `${basePath}?q=${encodeURIComponent(query)}`;

        fetch(url)
          .then((response) => response.json())
          .then((json) => {
            callback(json);
          })
          .catch(() => {
            callback();
          });
      },
    });
  }
}
